@import "/src/_styleguide/variables.scss";

.webhook-leads-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  pre {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 24px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .ant-collapse {
    border-radius: 10px;
    background: transparent;
    margin-bottom: 0;
    overflow: hidden;
    .ant-collapse-content {
      background-color: transparent;
      border-top: 0;
      .ant-collapse-content-box {
        padding: 0 12px 12px 12px !important;
      }
    }
    > .ant-collapse-item {
      background: #fff;
      transition: background-color 0.3s ease, border 0.3s ease;
      .ant-collapse-header-text {
        font-size: 14px;
        font-weight: 500;
        font-family: $font-main;
      }
      &.ant-collapse-item-active {
        background: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > .ant-collapse-header {
        padding: 12px !important;
      }
    }
  }
}
