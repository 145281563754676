@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.int-card {
  flex: 1 1 380px;
  max-width: 420px;
  min-width: 380px !important;
  height: 220px;
  background-color: #fff;
  padding: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  position: relative;
  &.is-live {
    box-shadow: 0px 0px 3px 1px rgba(68, 170, 68, 0.8);
  }
  button.barn-btn.boxed svg {
    stroke-width: 2 !important;
    width: 20px !important;
    height: 20px !important;
  }
  .pp-toggle {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .int-tags {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    .pp-tag {
      box-shadow: 1px 1px 0px 0px #f3f3f3;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
    img {
      width: 14px;
      height: 14px;
      position: relative;
      top: 3px;
    }
  }
  .int-info {
    padding: 16px;
    flex: 1;
    .int-brand {
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        height: 28px;
      }
      h3 {
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
        span {
          display: flex;
          align-items: center;
          .pp-tag {
            margin-left: 8px;
            svg {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 3em;
      line-height: 1.5em;
      color: #757575;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      margin: 12px 0 0 0;
    }
  }
  .int-actions {
    padding: 12px;
    border-top: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-secondary {
      width: 40px;
      text-align: center;
      justify-content: center;
    }
  }
}

// <div className="int-card">
//   <div className="int-info">
//     <div className="int-brand">
//       <img src={Twilio} alt="Twilio" />
//       <h3>Outlook</h3>
//     </div>
//     <p>
//       Outlook is a popular email client and productivity platform from
//       Microsoft, offering features like email management, calendar
//       scheduling, and contact organization for both personal and business
//       use.
//     </p>
//   </div>
//   <div className="int-actions">
//     <Tooltip title="">
//       <Button className="boxed" type="secondary">
//         <IconSettings />
//       </Button>
//     </Tooltip>
//     <Button className="boxed" type="primary">
//       <IconPlugConnected /> Connect
//     </Button>
//   </div>
// </div>
