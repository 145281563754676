.screening-settings {
  .pp-input-wrapper {
    width: 100%;
  }
  .se-action-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    svg {
      width: 20px;
      height: 20px;
      color: #757575;
    }
  }
}
