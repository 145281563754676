.admin-custom-fields {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .settings-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .ms-filters {
      display: flex;
      align-items: center;
    }

    .ms-actions {
      display: flex;
      align-items: center;
    }
  }
}
