@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.se-theme-settings {
  .logo-box {
    display: flex;
    width: 380px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px dashed #d9d9d9;
    background: #fafaf9;
    img {
      display: block;
      width: 68px;
      height: 68px;
      max-width: 68px;
      max-height: 68px;
      overflow: hidden;
      border-radius: 17px;
      margin-bottom: 0;
    }
    button {
      max-width: 120px;
      text-align: center;
      justify-content: center;
    }
    &.has-logo {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
}
