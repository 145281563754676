.settings-custom-fields {
  width: 100%;

  .settings-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .ms-filters {
      display: flex;
      align-items: center;
    }

    .ms-actions {
      display: flex;
      align-items: center;
    }
  }

  .field-type {
    font-weight: 500;
    color: #000;

    &.longtext {
      color: #1890ff;
    }

    &.number {
      color: #52c41a;
    }
  }
  .archived-row {
    opacity: 0.4;
  }
}
