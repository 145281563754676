@import "/src/_styleguide/variables.scss";

.pp-task-badge {
  &.pp-task-badge-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    box-shadow: 1px 1px 0px 0px #f3f3f3;
    svg {
      width: 14px;
      height: 14px;
    }
    &.pp-task-badge-large {
      width: 28px;
      height: 28px;
      border-radius: 10px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &.pp-task-badge-call {
      border: 1px solid #a2d683;
      background: #f2fdeb;
      svg {
        color: #2b600a;
      }
    }
    &.pp-task-badge-general {
      border: 1px solid #d7c0e5;
      background: #f4e6fd;
      svg {
        color: #6f07b0;
      }
    }
    &.pp-task-badge-email {
      border: 1px solid #a1d3e2;
      background: #e3f7fd;
      svg {
        color: #0a4b60;
      }
    }
    &.pp-task-badge-text {
      border: 1px solid #fcdeb0;
      background: #fff8ed;
      svg {
        color: #dd7904;
      }
    }
    &.pp-task-badge-payments {
      border: 1px solid #d7c0e5;
      background: #f4e6fd;
      svg {
        color: #6f07b0;
      }
    }
    &.pp-task-badge-credit {
      border: 1px solid #dcdcdc;
      background: #fff;
      svg {
        color: #000;
      }
    }
    &.pp-task-badge-mail {
      border: 1px solid #787774;
      background: #fff;
      svg {
        color: #787774;
      }
    }
    &.pp-task-badge-webhook {
      border: 1px solid #000;
      background: #fff;
      svg {
        color: #000;
      }
    }
  }
  &.pp-task-badge-full {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    padding-inline: 7px;
    box-shadow: 1px 1px 0px 0px #f3f3f3;
    svg {
      width: 14px;
      height: 14px;
    }
    &.pp-task-badge-call {
      border: 1px solid #a2d683;
      background: #f2fdeb;
      svg {
        color: #2b600a;
      }
    }
    &.pp-task-badge-general {
      border: 1px solid #d7c0e5;
      background: #f4e6fd;
      svg {
        color: #6f07b0;
      }
    }
    &.pp-task-badge-email {
      border: 1px solid #a1d3e2;
      background: #e3f7fd;
      svg {
        color: #0a4b60;
      }
    }
    &.pp-task-badge-text {
      border: 1px solid #fcdeb0;
      background: #fff8ed;
      svg {
        color: #dd7904;
      }
    }
    &.pp-task-badge-documents {
      border: 1px solid #f9d2ce;
      background: rgba(249, 210, 206, 0.4);
      svg {
        color: #000;
      }
    }
    &.pp-task-badge-payments {
      border: 1px solid #d7c0e5;
      background: #f4e6fd;
      svg {
        color: #6f07b0;
      }
    }
    &.pp-task-badge-credit {
      border: 1px solid #dcdcdc;
      background: #fff;
      svg {
        color: #000;
      }
    }
    &.pp-task-badge-mail {
      border: 1px solid #787774;
      background: #fff;
      svg {
        color: #787774;
      }
    }
    &.pp-task-badge-webhook {
      border: 1px solid #000;
      background: #fff;
      svg {
        color: #000;
      }
    }
  }
}
