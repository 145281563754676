@import "/src/_styleguide/variables.scss";

.self-enrollment-component {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
  .ms-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .se-left {
    flex: 1;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    padding: 48px 24px;
    .se-container {
      width: 100%;
      max-width: 516px;
      margin: 0 auto;
      .se-sub-header {
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
        letter-spacing: -0.4px;
        margin: 0 0 24px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .se-action-icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .se-form {
        .se-sub-label {
          color: #757575;
          font-family: $font-main;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          letter-spacing: -0.12px;
          margin-top: 4px;
        }
        > h3 {
          color: #000;
          font-family: $font-title;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          margin: 0 0 24px 0;
          position: relative;
        }
        .se-divider {
          height: 1px;
          width: 100%;
          border-top: 1px dashed #d9d9d9;
          margin: 24px 0 23px 0;
        }
        .se-horizontal {
          display: flex;
          align-items: center;
          gap: 12px;
          &.se-bottom {
            margin-bottom: 24px;
          }
          > div {
            flex: 1;
            margin: 0;
          }
          > label {
            width: 132px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
          }
        }
        .se-vertical {
          display: flex;
          flex-direction: column;
          &.se-bottom {
            margin-bottom: 24px;
          }
        }
      }

      .se-theme {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        box-shadow: 0 1px 4px 0 rgba(12, 12, 13, 0.05);
        height: 52px;
        padding: 10px 12px 10px 10px;
        > a {
          display: none;
        }
        > svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        span {
          flex: 1;
          font-size: 14px;
          color: #000;
          font-family: $font-main;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: -0.28px;
        }
        &:hover {
          border: 1px solid #757575;
          a {
            display: flex;
          }
          .page-actions {
            display: flex;
          }
        }
      }
      .se-divider {
        width: 100%;
        height: 1px;
        width: 468px;
        height: 1px;
        border-top: 1px dashed #d9d9d9;
        margin: 16px 0 15px 0;
      }
    }
  }
  .se-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    border-left: 1px solid #f3f3f3;
    max-width: 720px;
    min-width: 500px;
    width: 600px;
    background: #f5f5f4;
  }
  .se-banner {
    display: flex;
    padding: 6px 6px 6px 12px;
    align-items: center;
    border-radius: 12px;
    background: #292524;
    height: 44px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 24px;
    gap: 8px;
    .se-banner-icon {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: #fdbb74;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .se-banner-title {
      flex: 1;
      color: #fff;
      font-family: $font-main;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: -0.28px;
    }
  }
}
