@import "/src/_styleguide/variables.scss";

.hidden-pages {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  border-top: 1px dashed #d9d9d9;
  padding-top: 16px;
  svg {
    width: 20px;
    height: 20px;
    color: #9d9d9d;
  }
  h4 {
    color: #000;
    font-family: $font-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.28px;
    margin: 0;
  }
}

.settings-self-enrollment-flow {
  .flow-page-item {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(12, 12, 13, 0.05);
    margin-bottom: 8px;
    height: 52px;
    position: relative;

    .tabler-icon.tabler-icon-eye {
      width: 20px;
      height: 20px;
      color: #d0d2d4;
      position: absolute;
      right: 64px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      display: none;
    }

    .drag-handle {
      height: 100%;
      cursor: grab;
      margin-right: 12px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        color: #757575;
      }
      &:hover svg {
        color: #000;
      }
    }

    .page-number {
      font-family: $font-title;
      font-weight: 400;
      font-size: 14px;
      color: #9d9d9d;
      line-height: 21px;
      letter-spacing: -0.28px;
    }

    .page-name {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
    }

    .page-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0;
      h5 {
        margin: 0;
        font-size: 14px;
        color: #000;
        font-family: $font-main;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.28px;
        display: flex;
      }
      p {
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          width: 12px;
          height: 12px;
          position: relative;
          top: -1px;
          cursor: pointer;
          display: none;
        }
        margin: 0;
        color: #9d9d9d;

        font-family: $font-main;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
      }
    }

    .page-actions {
      display: none;
      align-items: center;
      justify-content: center;
      gap: 4px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 2;
      }
    }
    cursor: pointer;

    &.active {
      border: 1px solid #000;
      .page-actions {
        display: flex;
      }
    }

    &:hover {
      border: 1px solid #757575;
      .page-actions {
        display: flex;
      }
    }
    &.invalid {
      border: 1px solid #ff0000;
      .page-title {
        p {
          color: #ff0000;
          svg {
            display: block;
          }
        }
      }
    }
  }
}
