.pp-select-font {
  > .fontpicker {
    height: 48px;
    line-height: 48px;
    padding: 3px 12px;
    border-radius: 10px;
    border: 1px solid #ebebea;
    background: #fff;
    padding: 0 12px;
    outline: 0;
    box-shadow: none;
    border-radius: 6px;
    background: #ffffff;
    border: 1px solid #ebebea;
    box-shadow: none;

    .fontpicker__search {
      height: 48px;
      line-height: 48px;
      padding: 3px 12px;
      border-radius: 10px;
      border: 1px solid #ebebea;
      background: transparent;
      padding: 0 12px;
      outline: 0;
      box-shadow: none;
      border-radius: 6px;
      border: 0;
      box-shadow: none;
      color: transparent;
      &::selection {
        background: transparent;
      }
      &::-moz-selection {
        background: transparent;
      }
      &::placeholder {
        color: transparent;
      }
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
    }

    .fontpicker__popout {
      padding: 4px;
      color: rgba(0, 0, 0, 0.88);
      background-color: #ffffff;
      border-radius: 8px;
      outline: none;
      border: 0;
      top: calc(100% + 4px);
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      .fontpicker__option {
        border-radius: 4px;
        cursor: pointer;
        filter: none !important;
        padding: 4px 8px;
        margin-bottom: 2px;
        &:hover {
          background-color: #f0f0f0;
        }
        &.current {
          background-color: #a2d683;
        }
      }
    }

    .fontpicker__preview {
      filter: none !important;
      position: relative;
      top: 10px;
    }
    // > input.fontpicker__search {
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 16px;
    //   color: #151414;
    // }
  }
}
