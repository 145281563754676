.mobile-device-preview {
  .mobile-device-frame {
    width: 360px;
    height: calc(100vh - 108px);
    max-height: 780px;
    border-radius: 22.18px;
    border: 0.5px solid #d9d9d9;

    background: #fff;

    /* Drop Shadow/200 */
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    .mobile-device-top {
      height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      z-index: 10;
      position: relative;
      color: #000;
      text-align: center;
      font-family: "SF Pro Text", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.296px;
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
      }
    }

    .mobile-device-bottom {
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 0 0 0;
      z-index: 10;
      position: relative;
      border-top: 0.462px solid rgba(60, 60, 67, 0.36);
      > div {
        display: flex;
        align-items: center;
        gap: 6px;
        text-align: center;
        color: #000;
        font-family: "SF Pro Text", "Helvetica Neue", Helvetica, Arial,
          sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        svg {
          width: 10px;
          height: 10px;
          color: rgba(60, 60, 67, 0.6);
          position: relative;
        }
      }
    }

    .mobile-device-notch {
      position: absolute;
      height: 24px;
      width: 100px;
      background: #000;
      border-radius: 12px;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .bottom-notch {
      position: absolute;
      height: 4px;
      width: 124px;
      background: #000;
      border-radius: 2px;
      z-index: 11;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
    }

    .mobile-device-screen {
      width: 100%;
      height: calc(100% - 108px);
      overflow: hidden;

      .preview-iframe {
        width: 100%;
        height: 100%;
        border: none;
        //   pointer-events: none;
        //   -webkit-user-select: none;
        //   -moz-user-select: none;
        //   -ms-user-select: none;
        //   user-select: none;
      }
    }
  }
}
